<template>
  <v-app>
    <v-main >
      <router-view/>
      <SideBarPhone />
      <Login/>
      <Footer />
      <RightSide/>
      <Search/>
    </v-main>
  </v-app>
</template>
<script>
import Footer from "@/components/Global/Footer.vue";

const debounce = (fn, delay) => {

  let timer = null;

  return function () {

    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {

      fn.apply(context, args);

    }, delay);

  }

}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

  constructor(callback) {

    callback = debounce(callback, 16);

    super(callback);

  }

}
import SideBarPhone from "@/components/SideBar-Phone.vue";
import Login from "@/components/Global/Login.vue";
import {mapMutations} from "vuex";
import RightSide from "@/components/Global/RightSide.vue";
import Search from "@/components/Global/Search.vue";

export default {
  name: 'App',
  components: {Search, RightSide, Footer, Login, SideBarPhone},
  data() {
    return {

    }
  },
  methods:{
    ...mapMutations(['backLoginWindow']),
  },
  mounted() {
    document.addEventListener('click', this.backLoginWindow)
  }
};
</script>

<style>
::selection {
  background-color: #00C4B6;
  color: #ffffff;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Lato, Roboto, 'PingFang SC', 'Microsoft YaHei', sans-serif;
}
html,body{
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 2;
  overflow: auto;
}
body:before{
  position: fixed;
  top: 0;
  left: 0;
  width: var(--width);
  height: 100%;
  content: '';
  background-color: rgba(0,0,0,0.7);
  transition:width 0.5s ease;
  z-index: 2;
}
:root{
  --width: 0;
}

/* 整个滚动条的轨道 */
body::-webkit-scrollbar {
  width: 6px; /* 滚动条宽度 */

}

/* 滚动条的滑块部分 */
body::-webkit-scrollbar-thumb {
  background-color: #49B1F5; /* 滑块背景色 */
  border-radius: 6px; /* 滑块圆角 */
}

/* 滑块悬停时的样式 */
body::-webkit-scrollbar-thumb:hover {
  //background-color: #555; /* 悬停时的背景色 */
}

/* 滑块激活时的样式 */
body::-webkit-scrollbar-thumb:active {
  //background-color: #333; /* 激活时的背景色 */
}

/* 轨道的背景颜色 */
body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 轨道背景色 */
}
</style>
